.c-delete-mybarcelo-form {
	&-selection {
		font-size: 16px;
		font-weight: 700;
		line-height: 30px;
	}
	.cancel-myb {
		border-top: 1px solid @grey-40;
		width: 100%;
		padding: 0.75rem;
		&-label {
			font-size: 18px;
			font-weight: 500;
			line-height: 22px;
			padding-left: 0.5rem;
			margin-bottom: 0;
		}
	}
	.cancel-myb-last {
		border-bottom: 1px solid @grey-40;
	}
	.accordion-item:after {
		display: none;
	}
}
