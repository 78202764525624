.call-me-now-form {
    .tingle-modal-box {
        max-width: 536px;
    }
    .c-callmenow {
        &__modal-form { 
            max-width: none;
            padding: 40px 24px 24px;
            .c-callmeback__modal-form-title {
                max-width: none;
            }
            .c-fieldset {
                &__row {
                    &.w-1\/2 {
                        flex-wrap: initial;
                    }
                    [for="call-me-now"], 
                    [for="not-call-me-now"] {
                        display: flex;
                        align-self: center;
                        overflow: visible;
                        text-overflow: initial;
                        font-size: 14px;
                        white-space: normal;
                    }
                }
                label.c-callmenow__modal-form-question {
                    margin-bottom: 14px;
                }
            }
        }
    }
}

.call-me-now-form {
    .tingle-modal-box {
        max-width: 422px;
        border-radius: 16px;
    }
}

@media screen and (orientation:landscape) {

}

@media (max-height: 800px) {
    .call-me-now-form {
        .tingle-modal {
            overflow-y: scroll;
            .tingle-modal-box {
                min-height: 770px;
            }
        }
    }
}

@media (min-width: @desktop) {

}