/*                  own                 */
.cmp-widget-faq.inbenta-faq {
    font-family: Museo Sans,Helvetica,Arial,sans-serif;
}

.centered {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.centeredText {
    text-align: center;
}

.removedMargin {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.barceloSubtitle {
    font-weight: 700;
    font-size: 20px;
    color: #888C8F;
    line-height: 28px;
}

/*                  searchbox                 */
.cmp-widget-faq__title {
    font-size: 36px;
    line-height: 42px;
    font-weight: 300;
    text-align: center;
}
.cmp-widget-faq__subtitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
    margin-top: 4px;
}
.cmp-widget-faq__search-box {
    width: 100%;
    max-width: 727px;
    margin: 0 auto;
    padding: 16px 24px 32px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}
.inbenta-km__search {
    padding-top: 5vh;
    margin-bottom: 0;
}

/*                  searchbox search-button                 */
.cmp-widget-faq.inbenta-faq .inbenta-km__search__form {
    box-shadow: none;
    border-radius: 8px;
}
.inbenta-faq .inbenta-km__search__form .form__button {
    position: absolute;
}
.cmp-widget-faq.inbenta-faq .inbenta-km__search__form .form__input.inbenta-km-input {
    font-weight: 300;
    padding: 8px 12px 8px 46px;
    border: 1px solid #D7D9DA;
    border-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: none;
}
.cmp-widget-faq.inbenta-faq .inbenta-km__search__form .form__button .inbenta-km-button {
    height: 56px;
    box-shadow: none;
    border-radius: 8px;
    border: none;
    background: none;
    padding: 0 14px;
}
.inbenta-faq .form__button .inbenta-km-button:hover {
    background-color: transparent;
    border: none;
}
.inbenta-km__search__form .form__button .inbenta-km-button.inbenta-km-button—-disabled {
    background-color: transparent;
}
.inbenta-km-button—-disabled, 
.inbenta-km-button—-disabled:hover {
    cursor: not-allowed;
}
.cmp-widget-faq.inbenta-faq .inbenta-km__search__form .form__button .inbenta-km-button .inbenta-km-icon:before {
    font-weight: 100!important;
    color: #384044;
    font-size: 18px;
}

/*                  autocompleter                 */
.inbenta-km__autocompleter {
    // font-weight: 700;
}

/*                  results                 */
.not-found__text {
    font-weight: 500;
}

.title__faq__text {
    font-weight: 300;
    color: #384044;
}

.body__contents__item {
    font-weight: 300;
    color: #606669;
}

/*                  categories                 */

.inbenta-km__categories {
    margin-top: 40px;
}
 
.inbenta-faq .inbenta-km__categories .inbenta-km-title {
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 40px;
    color: #384044;
    text-align: center;
}
.inbenta-faq .inbenta-km__categories .inbenta-km__category {
    max-width: 49.5%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.inbenta-faq .inbenta-km__categories .inbenta-km__category:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.inbenta-km__category .content-category__title {
    font-weight: 300;
    color: #384044;
    background-color: #F5F5F5;
    padding: 8px 10px 8px 16px;
}

.content-category__title {
    color: #384044;
}

.content-category__icon:before {
    color: #384044;
}

.container__category-name__title,
.inbenta-faq .inbenta-km__categories__container .container__category-name__title {
    font-family: Museo Sans,Helvetica,Arial,sans-serif;
    font-weight: 300;
    color: #384044;
}

.container__category-name__button-wrapper .inbenta-km-button {
    background-color: #155583;
    border: #155583;
    border-radius: 24px;
    font-family: Museo Sans,Helvetica,Arial,sans-serif;
    font-weight: 500;
    font-weight: 500;
    min-width: 100px;
    display: flex;
    justify-content: center;
}
.container__category-name__button-wrapper .inbenta-km-button:hover {
    background-color: #0079CF;
    border: 0;
}

.inbenta-km__categories__container .container__category-name__title {
    font-weight: 300;
}

.inbenta-km__categories .inbenta-km__categories__container .content-category__icon.inbenta-km-icon {
    display: none;
}
.inbenta-km__categories .inbenta-km__categories__container .title__icon .categoryIcon {
    width: 38px;
    height: auto;
    margin-right: 16px;
}
.inbenta-km__categories .inbenta-km__categories__container .title__icon {
    display: flex;
}
.inbenta-km__categories .inbenta-km__categories__container .content-category__title .category_title {
    font-family: Museo Sans,Helvetica,Arial,sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: #384044;
}

/*                  content rating                 */
.inbenta-km__rating__content {
    display: flex;
    flex-direction: column;
}

.body__rating .content__buttons {
    margin-top: 20px;
}

.body__rating .inbenta-km-icon {
    font-size: 100px;
}

.inbenta-faq .body__rating .inbenta-km-button {
    border-radius: 50px;
    height: 50px;
    width: 50px;
    padding: 0px;
    background-color: #155583;
    border-color: #155583;
}
.inbenta-faq .body__rating .inbenta-km-button:hover {
    background-color: #0079CF;
    border: 0;
}
.inbenta-faq .body__rating .inbenta-km-icon {
    font-size: 0px;
    text-align: center;
    width: 100%;
    margin: auto; 
}
.inbenta-km__rating {
    font-size: 17px;
}

.body__rating .inbenta-km-icon {
    font-size: 0px;
    margin-left: 30px;
}

.inbenta-km__rating {
    display: flex;
    justify-content: center;
    font-weight: 500;
}

/*                  categories                */
.inbenta-km__rating__comment {
    display: none;
}

.content-category__icon {
    /* removed default icon */
    display: none;
}

.categoryIcon {
    /* customIcon */
    width: 38px;
    height: auto;
    margin-right: 16px;
}

.content-category__title {
    justify-content: flex-start;
}

.category_title {
    width: 100vw;
    text-align: left;
    font-weight: 300;
    color: #384044;
}

.categoryRightArrow {
    width: 22px;
    height: auto;
}

.container__category-name__title {
    display: flex;
    align-items: center;
    width: 90%;
}

.container__category-name__title .categoryRightArrow {
    display: none;
}

/* results */
.inbenta-km__results .inbenta-km__results__extended {
    box-shadow: none;
}
.inbenta-km__results .inbenta-km__results__extended .inbenta-km__faqs {
    padding: 0;
}

/* sub faqs */
.inbenta-km__categories__container .container__category-contents .inbenta-km__faqs .inbenta-km__faq{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.inbenta-km__categories__container .container__category-contents .inbenta-km__faqs .inbenta-km__faq:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.inbenta-km__categories__container .container__category-contents .inbenta-km__faqs .inbenta-km__faq .title__faq {
    padding: 0;
}
.inbenta-km__categories__container .container__category-contents .inbenta-km__faqs .inbenta-km__faq .title__faq .title__faq__text {
    font-family: Museo Sans,Helvetica,Arial,sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
}
.inbenta-km__categories__container .container__category-contents .inbenta-km__faqs .inbenta-km__faq .inbenta-km__faq__title {
    background-color: #F5F5F5;
    padding: 8px 10px 8px 16px;
}
.inbenta-km__categories__container .container__category-contents .inbenta-km__faqs .inbenta-km__faq.inbenta-km__faq--active .inbenta-km__faq__title {
    background-color: white;
}
.inbenta-km__categories__container .container__category-contents .inbenta-km__faqs .inbenta-km__faq .title__faq__icon:before {
    font-family: barcelo-ui-kit!important;
    content: "\e902";
    color: #384044;
    width: auto;
    height: 22px;
}

.inbenta-km__categories__container .container__category-contents .inbenta-km__faqs .inbenta-km__faq--active .title__faq__icon:before {
    transform: rotate(90deg);
}
