/* SUMMARY MORE FREQUENTS FAQS - MOBILE FIRST (~ TABLET) */
.c-summary-more-frequents-faq {
    .windowed;
    background: white;
    padding: 10px 0px;
    margin: 15px 0px;
    .cmp-title {
        margin-bottom: 10px;
        padding: 0px 15px;
        &__text {
            font-size: 24px;
            line-height: 36px;
        }
    }
    &__list {
        padding: 0px 15px;
        &-item {
            margin-bottom: 5px;
            &-link {
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
            }
        }
    }
    &__footer {
        padding: 0px 15px;
        border-top: 1px solid @grey-30;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-top: 23px;
        padding-bottom: 13px;
        &-link {
            font-size: 14px;
            line-height: 24px;
            font-weight: 500;
        }
    }
}
/* SUMMARY MORE FREQUENTS FAQS - DESKTOP */
@media (min-width: @desktop) {
    .c-summary-more-frequents-faq {
        padding: 30px 0px 0px;
        .cmp-title,
        &__list,
        &__footer {
            padding: 0px 55px;
        }
        &__footer {
            padding-top: 18px;
            padding-bottom: 18px;
        }
    }
}
