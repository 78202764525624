/* CATEGORIES SUMMARY FAQ - MOBILE FIRST - (~ TABLET) */
.c-categories-summary-faq {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    &__item {
        width: 100%;
        margin-bottom: 26px;
        display: flex;
        align-items: center;
        .unstyle-anchor;
        .svg-icon {
            .mod--size-48;
            margin-right: 15px;
        }
    }
}
/* CATEGORIES SUMMARY FAQ - DESKTOP */
@media (min-width: @desktop) {
    .c-categories-summary-faq {
        flex-flow: row;
        align-items: center;
        justify-content: space-around;
        &__item {
            width: auto;
            flex-flow: column;
            justify-content: center;
            .svg-icon {
                margin: 13px auto;
            }
        }
    }
}
