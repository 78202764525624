@import (once) "../../../../../../../../node_modules/flag-icons/less/flag-icons-base.less";
@import (once) "../../../../../../../../node_modules/flag-icons/less/variables.less";

.grecaptcha-badge {
    visibility: hidden;
}

.c-help-forms {
    .c-cta-file {
        max-width: 300px;
        position: relative;
        cursor: pointer;
        padding: 1px 0 1px 40px;
    }
    .radio-error {
    }
    .c-cta-file::-webkit-file-upload-button {
        visibility: hidden;
    }
    .c-cta-file:before {
        content: attr(value);
        position: absolute;
        background: @white;
        color: @white;
        width: 110px;
        height: 25px;
    }
    .c-cta-file:after {
        content: attr(value);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: auto;
        min-width: 125px;
        background: white;
        color: @grey-90;
        border: solid 2px @grey-90;
        text-align: center;
        font-size: 14px;
        line-height: 23px;
        padding: 0px 20px;
        border-radius: 15px;
        text-decoration: none;
    }
    .c-help-forms__checkbox {
        position: absolute;
        margin-top: 5px;
    }
    .policy-label {
        font-size: 14px;
        font-weight: 300;
        line-height: 26px;
        display: inline-block;
        white-space: initial;
        line-height: 20px;  
        .required:after {
            content: none;
        }
        .required span:after {
            content: " *";
        }
    }

    .recaptcha-label{
        font-size: 14px;
        font-weight: 300;
        line-height: 26px;
        display: inline-block;
    }

    .c-fieldset__form-radio {
        h5 {
            text-align: left;
        }
        input[type="radio"] {
            margin: 0 6px;
        }
        label {
            margin-left: 10px;
            line-height: 26px;
            display: inline-flex;
            .remove-ellipsis;
        }
    }
    .c-fieldset__row-attach {
        display: inline-block;
        text-align: center;
        .mod--cta-outline {
            .c-cta {
                padding: 0.15rem 1.2rem;
                font-size: 13px;
            }
        }
    }
    .c-fieldset__row {
        #comment {
            min-height: 6em;
        }
        #checkin {
            .mix-background-sprite(38px, 17, 0px);
        }
        #checkout {
            .mix-background-sprite(38px, 19, 0px);
        }
        &-tlf {
            .mod--with-icon {
                max-width: 70px;
                margin-right: -2px;
                .flag-icon-es {
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0px 0.25rem;
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    background-position: left center;
                    .flag-icon(es);
                }
            }
        }
    }
    .mod--cta-min-width-200 {
        .c-cta {
            min-width: 200px;
        }
    }
    .c-fieldset__row.c-fieldset__form-radio {
        flex-wrap: initial;
    }
    &__rooms-container {
        width: 100%;
        .c-fastbooking__submenu-rooms {
            width: 100%;
        }
    }
    // dropdown
    .c-fieldset {
        .c-popover {
            margin-left: 0;
            left: 5px;
            width: calc(100% - 10px);
        }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .c-help-forms {
        .c-fieldset__form-radio {
            label {
                display: inline-flex;
            }
            &:last-child {
                text-align: left;
            }
        }
        .c-fieldset__row-wrap {
            align-items: flex-start;
        }
        &__rooms {
            &-container {
                .c-fastbooking__submenu-rooms {
                    margin: 27px 15px;
                    width: ~"calc(100% - 30px)";
                    max-width: inherit;
                    .c-help-forms__rooms-reset {
                        padding-left: 20px;
                    }
                    .c-incrementers {
                        width: 60%;
                        padding-left: 13px;
                        &__btn {
                            flex-shrink: 0;
                        }
                    }
                    .c-label-incrementers {
                        width: auto;
                    }
                    &-room-body {
                        display: flex;
                        flex-wrap: wrap;
                        position: relative;
                        padding-bottom: 50px;
                        .c-fieldset,
                        .c-fieldset__row {
                            margin: 0px;
                            padding: 0px;
                        }
                        .c-fieldset__row-wrap {
                            padding-left: 0px;
                            padding-right: 0px;
                            align-items: center;
                        }
                    }
                    .children-age {
                        width: 50%;
                        border-top: none;
                        justify-content: flex-start;
                        margin-top: 0px;
                        padding-top: 0px;
                        padding-left: 20px;
                        &:before {
                            content: none;
                        }
                        &-column {
                            display: flex;
                            justify-content: space-between;
                            margin: 0px;
                            &-item {
                                width: 20%;
                                padding: 0px;
                                margin-right: 20px;
                                select {
                                    width: 65px;
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                }
            }
            &-adults,
            &-children {
                width: 25%;
            }
            &-reset {
                position: absolute;
                bottom: 0px;
                left: 0px;
            }
        }
        // dropdown
        .c-fieldset {
            .c-popover {
                margin-left: 1px;
                width: auto;
            }
        }
    }
}
