/* FAQ - MOBILE FIRST (~ TABLET) */
.c-faq {
    display: flex;
    flex-flow: column;
    margin: 10px auto;
    flex-wrap: wrap;

    &__nav {
        display: flex;
        height: 62px;
        overflow-x: auto;
        overflow-y: hidden;
        width: auto;
        margin-bottom: 20px;
        .cmp-image {
            display: none;
        }
        &-item {
            cursor: pointer;
            display: flex;
            flex-flow: column;
            background: @grey-30;
            font-size: 12px;
            line-height: 20px;
            font-weight: 500;
            text-align: center;
            padding: 10px 4px;
            margin: 0px 1px;
            align-items: center;
            justify-content: center;
            min-width: 82px;
            max-width: 100px;
            &-active {
                background: var(--b-blue);
                color: white;
            }
            .svg-icon {
                display: none;
            }
            & > a.cmp-text__paragraph {
                text-decoration: none;
                color: #000000; 
            }
        }
        // responsive-grid
        .aem-Grid {
            cursor: pointer;
            // display: flex;
            display: none;
            .c-textimage {
                background: @grey-30;
                height: 62px;
                padding: 10px 4px;
                margin: 0px 1px;
                min-width: 82px;
                max-width: 100px;
                > * {
                    width: 100%;
                }
                &:hover {
                    color: @grey-90;
                }
                .icon {
                    display: none;
                }
                .generic-text {
                    width: 100%;
                    .cmp-text {
                        width: 100%;
                        color: @grey-90;
                        font-size: 12px;
                        line-height: 20px;
                        font-weight: 500;
                        text-align: center;
                    }
                }
            }
            .generic-text {
                .cmp-text {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    text-align: center;
                    letter-spacing: 0.0015em;
                    color: #000000; 
                    p {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
        .generic-container .aem-Grid {
            display: block;
        }

    }
    &__content {
        width: 100%;
        .cmp-title {
            padding-bottom: 16px;
            &__text {
                font-size: 20px;
                line-height: 32px;
                text-align: left;
            }
        }
        .cmp-text {
            padding-bottom: 33px;
        }
        .accordion-item-faq {
            > .accordion-toggle {
                border-bottom: 2px solid @grey-30;
                flex-direction: row-reverse;
                justify-content: space-between;
                &:hover {
                    color: @grey-90;
                }
            }
            &:after {
                content: none;
            }
            > .accordion-content {
                font-size: 18px;
                line-height: 28px;
            }
            &.active {
                > .accordion-content {
                    margin: 10px 0px;
                }
            }
        }
        &--ctas {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 40px;
            .cta {
                margin-right: 16px;
                .c-cta {
                    min-width: 260px;
                }
            }
        }
    }
    .breadcrumb {
        flex-basis: 100%;
        margin-top: 26px;
        margin-bottom: 18px;
        .cmp-breadcrumb {
            display: block;
        }
    }

    .c-myb-content {
        margin-bottom: 38px;
    }
}

/* FAQ - DESKTOP */
@media (min-width: @desktop) {
    .c-faq {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;

        &__nav {
            flex-flow: column;
            height: auto;
            // width: 200px;
            // width: 332px;
            width: 306px;
            overflow: inherit;
            padding-top: 5px;
            margin: 0px 20px 0px 0px;
            flex-shrink: 0;
            .cmp-image {
                display: block;
                width: 218px;
                height: auto;
                margin-bottom: 32px;
                img {
                    object-fit: fill;
                }
            }
            &-item {
                flex-flow: row;
                background: transparent;
                font-size: 16px;
                line-height: 24px;
                font-weight: 300;
                text-align: left;
                padding: 0px;
                margin: 0px 0px 20px;
                justify-content: flex-start;
                min-width: inherit;
                max-width: inherit;
                &-active {
                    background: transparent;
                    color: @grey-90;
                    font-weight: bold;
                }
                .svg-icon {
                    .mod--size-20;
                    display: inherit;
                    margin-right: 9px;
                    flex-shrink: 0;
                }
            }
            // responsive-grid
            .aem-Grid {
                margin: 0;
                padding: 0;
                margin-top: 25px;
                display: block;
                // .c-textimage {
                //     display: flex;
                //     flex-flow: row;
                //     justify-content: flex-start;
                //     background: transparent;
                //     height: inherit;
                //     padding: 0px;
                //     margin: 0px 0px 20px;
                //     min-width: inherit;
                //     max-width: inherit;
                // }
                .generic-container .aem-Grid {
                    display: block;
                    padding: 21px 0 32px;
                    margin-top: 0;
                }
                .icon {
                    .mod--size-32;
                    display: inherit;
                    // margin-right: 9px;
                    flex-shrink: 0;
                }
                .generic-text {
                    .cmp-text,
                    .cmp-text h4 {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        margin: 13px 24px 24px;
                        text-align: center;
                        p {
                            font-size: 16px;
                            line-height: 24px;
                            font-weight: 400;
                        }
                    }
                    .cmp-text h4 {
                        margin: 0 0 8px;
                    }
                }
            }
        }
        &__content {
            padding-left: 25px;
            border-left: 1px solid @grey-30;
            width: ~"calc(100% - 328px)";
            .cmp-title {
                &__text {
                    font-size: 28px;
                    line-height: 36px;
                    letter-spacing: 0.0015em;
                }
                // border-bottom: 1px solid @grey-30;
            }
            
            .accordion-item-faq {
                > .accordion-toggle {
                    border-bottom: 1px solid @grey-30;
                    &:hover {
                        color: @grey-90;
                    }
                }
                &:after {
                    content: none;
                }
                &.active {
                    > .accordion-content {
                        margin: 10px 0px;
                    }
                }
            }

            &--ctas {
                margin-top: 80px;
            }
        } 
        .breadcrumb {
            margin-top: 72px;
            margin-bottom: 64px;
        }

        .c-myb-content {
            margin-bottom: 68px;
        }
    }
}
