
.cancelation-modal-form {
	.tingle-modal__close {
		top: 24px;
		right: 24px;
		.tingle-modal__closeIcon {
			width: 24px;
    		height: 24px;
		}
	}
}

.c-cancelation__modal {
	padding: 56px 16px;
	&-title{
		font-weight: 600;
		letter-spacing: 0.042px;
		max-width: none;
	}
	&-text {
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: 24px;  
		letter-spacing: 0.08px;
	}
	&-icontext {
		display: flex;
		margin: 16px 0;
		align-items: center;
		.svg-icon {
			margin: 0 16px;
			width: 28px;
			height: 28px;
		}
		span {
			font-weight: 400;
			line-height: 24px; 
			letter-spacing: 0.08px;
		}
	}
	.c-fieldset {
		margin-top: 24px;
		&__row {
			display: block;
		}
		select {
			border-radius: 8px;
			background-image: url(/content/dam/barcelo/commons/icons/ui/arrow_down.svg);
		    background-position: 96%;
			background-size: 14px auto;
			height: 48px;
		}
		&.c-cancelation__modal-reasons__container {
			margin-bottom: 0;
		}
	}
	&.c-callmeback__modal-form {
		.c-fieldset__row {
			display: inherit;
			&-wrap {
				input[type=checkbox].cancelation-callme-input-contact-policy {
					width: 18px;
					height: 18px;
					min-width: 18px;
					flex-shrink: 0;
					margin: 0 3px;
					position: relative;
					border-radius: 0;
					&:checked {
						background: #384044;
					}
				}
				.policy-label {
					cursor: pointer;
				}
			}
		}
	}
}

@media (min-width: @desktop) {
	.c-cancelation__modal {
		padding: 72px 48px 56px 48px;
	}
}