.c-callmeback {
    position: fixed;
    right: -100%;
    margin-right: 1pc;
    bottom: 4pc;
    height: 80px;
    width: 80px;
    overflow: visible;
    z-index: 5;
    display: flex;
    .show-right;
    &__bubble {
        &--image {
            position: relative;
            align-self: flex-end;
            cursor: pointer;
            height: 80px;
            width: 80px;
            overflow: hidden;
            border-radius: 50%;
            border-radius: @circle;
            border: 3px solid white;
            box-shadow: 0px 2px 12px 0px rgb(0 0 0 / 30%);
            background-color: @white;
            .cmp-image {
                border-radius: @circle;
            }
        }
        &--phone {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            width: 28px;
            bottom: -6px;
            right: 6px;
            border-radius: @circle;
            background-color: var(--b-blue);
            box-shadow: 0px 2px 12px 0px rgb(0 0 0 / 30%);
            .svg-icon {
                height: 15px;
                width: 15px; 
            }
        }
    }
    
    &__banner {
        position: absolute;
        border-radius: @circle;
        background-color: @white;
        white-space: nowrap;
        overflow: hidden;
        height: 48px;
        top: 50%;
        right: 0;
        margin-right: 16px;
        padding: 12px 16px;
        transform: translateY(-50%);
        box-shadow: 0px 2px 12px 0px rgb(0 0 0 / 30%);
        z-index: -1;
       
        &--claim {
            font-size: 16px;
            line-height: 24px;
            margin-right: -330px;
            transition: all 0.3s ease-in-out;
            &-close {
                cursor: pointer;
                display: inline-flex;
                margin: 0 8px 0 10px;
                z-index: 1;
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                .svg-icon {
                    width: 13px;
                    height: 13px;
                }
            }
        }
        &:hover {
            .c-callmeback__banner--claim {
                text-decoration: underline;
            }
        }
    }
    &:hover .c-callmeback__banner--claim {
        display: inline-block;
        width: 100%;
        margin-right: 0;
        padding-right: 62px;
        transition: all 0.3s ease-in-out;
        
    }
    .tingle-modal-box__content {
        height: 100vh;
        overflow: auto;
    }
    &__modal-form { 
        max-width: 400px;
        margin: 0 auto;
        padding: 24px;
        &-title {
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            max-width: 210px;
            margin: 0 auto 16px auto;
        }
        &-subtitle {
            font-size: 18px;
            line-height: 28px;
        }
        &-text {
            text-align: center;
            margin-bottom: 12px;
        }
        form.rounded {
            input:not(input[type="checkbox"]):not(input[type="radio"]),
            .iti__flag-container {
                border-radius: 8px;
            }
            input[type=tel].mod--invalidate {
                border-color: @red-error;
            }
            input::placeholder {
                font-size: 16px;
                font-weight: 300;
                line-height: 24px;  
                letter-spacing: 0.08px;
                color: @grey-75; //#606669
            }
        }
        .c-fieldset {
            margin: 10px 0px;
            &.mod--cta-full-width {
                margin: 10px 0;
            }
            &__row,
            &__row-wrap {
                position: relative;
                .policy-label {
                    white-space: normal;
                    &.required {
                        p {
                            display: inline;
                        }
                    }
                }
                span {
                    line-height: 20px;
                }
                input[type="radio"] {
                    width: 24px;
                    height: 24px;
                    border-radius: 99px;
                    position: relative;
                    margin: auto 4px auto 0;
                    flex-shrink: 0;
                    @media (min-width: @desktop) {
                        margin: auto 12px auto 0;
                    }
                    &:checked {
                        background: @grey-90;
                        &:before {
                            position: absolute;
                            content: "\e926";
                            font-family: barcelo-ui-kit;
                            font-size: 14px;
                            line-height: 0.8;
                            top: 6px;
                            left: 4px;
                            color: @white;
                        }
                    }
                    & ~ label {
                        font-weight: 500;
                        line-height: 20px;
                        cursor: pointer;
                        margin-top: 3px;
                    }
                    
                }
                input[type="checkbox"] {
                    &.mod--small {
                        &:after {
                            font-size: 10px;
                        }
                    }
                }
                input[type="checkbox"], 
                input[type="radio"] {
                    &.mod--invalidate {
                        border-color: @red-error;
                        background: @white;
                        box-shadow: inset 0px 1px 4px 0px rgb(186, 34, 34, 0.5);
                    }
                }
                
                .iti--allow-dropdown {
                    &::after {
                        content: "";
                        border: 1px solid @grey-45;
                        border-radius: 8px;
                        width: calc(100% - 64px);
                        position: absolute;
                        height: 40px;
                        right: 0;
                    }
                    & ~ .contact-tel-prefix {
                        position: absolute;
                        left: 96px;
                        top: 8px;
                        z-index: 1;
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 24px;  
                        letter-spacing: 0.08px;
                        color: @grey-75; //#606669
                        span {
                            padding-left: 2px;
                        }
                    }
                    input.intlTelInput {
                        position: relative;
                        float: right;
                        width: calc(100% - 84px);
                        padding-left: 58px;
                        padding-right: 1.5rem;
                        background-color: transparent;
                        z-index: 1;
                        &.pl-50 {
                            padding-left: 50px;
                        }
                        &.pl-68 {
                            padding-left: 68px;
                        }
                    }
                }
                .iti__flag-container {
                    border: none;
                }
                .iti__selected-flag {
                    background: transparent;
                    border: 1px solid @grey-45;
                    border-radius: 8px;
                    .iti__arrow {
                        border: 0;
                        &::after {
                            content: "›";
                            transform: rotate(90deg);
                            background: transparent;
                            left: auto;
                            font-size: 24px;
                            margin-left: 3px;
                            top: 3px;
                        }
                    }
                }
                .iti__country-list {
                    border-radius: 8px;
                    top: 62px;
                    width: 100%;
                    height: 218px;
                    min-height: 218px;
                    padding: 10px 0;
                    &::-webkit-scrollbar {
                        width: 22px;
                    }
                    &::-webkit-scrollbar-track {
                        background-color: #EAEEF1;
                        border-right: 16px solid #fff;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: var(--b-aquamarine-blue);
                        border-right: 16px solid white;
                        border-top: 10px solid #fff;
                    }
                    &::-webkit-scrollbar-button:vertical:increment,
                    &::-webkit-scrollbar-button:vertical:decrement {
                        height:16px;
                    }
                    /* Works on Firefox */
                    & {
                        scrollbar-width: thin;
                        scrollbar-color: var(--b-aquamarine-blue) transparent;
                    }

                    .iti__divider {
                        display: none;
                    }
                }
                .c-cta {
                    &.mod--disabled {
                        text-decoration: none;
                        cursor: not-allowed;
                        pointer-events: none;
                        background: @grey-50;
                        filter: grayscale(1);
                        opacity: 0.3;
                    }
                }
                &.w-1\/2 {
                    align-items: center;
                    flex-flow: row;
                    label {
                        text-overflow: initial;
                        overflow: visible;
                    }
                }
                input[type=checkbox].call-me-back-input-contact-policy {
                    width: 18px;
                    height: 18px;
                    min-width: 18px;
                    flex-shrink: 0;
                    margin: 0 3px;
                    position: relative;
                    border-radius: 0;
                    &:checked {
                        background: @grey-90;
                        &::after {
                            position: absolute;
                            top: -1px;
                            left: 1px;
                        }
                    }
                    & ~ .policy-label {
                        margin-left: 12px;
                        cursor: pointer;
                        &::after {
                            content: none;
                        }
                    }
                }
            }
        }
    }

    &__modal--success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 200px;
        max-width: 400px;
        padding: 10px 20px;
        margin: 0 auto;
        &-icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0 16px 0;
            &_bg {
                width: 86px;
                height: auto;
            }
            &_animation {
                position: absolute;
                width: 45px;
                height: auto;
                opacity: 0;
                animation: fadeIn 0.5s 0.5s ease-in forwards;
            }
        }
        &-title {
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            margin-bottom: 16px;
        }
        &-text {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin: 0 auto 16px auto;
            max-width: 300px;
        }
        &-logo {
            width: 160px;
            margin: 0 auto 16px auto;
        }
    }

    // MYBARCELO UNIQUE 
    &.c-callmeback--myb-unique {
        position: relative;
        right: initial;
        margin-right: 0;
        bottom: initial;
        height: auto;
        width: 254px;
        overflow: visible;
        z-index: initial;
        display: block;

        animation: none;
        .c-callmeback--myb-unique {
            &__container {
                display: block;
                padding: 48px 16px 25px;
                border-radius: 16px;
                background-color: black;
            }
            &__image {
                position: absolute;
                width: 86px;
                top: -12px;
                right: 4px;
                img {
                    width: 86px;
                }
            }
            &__title,
            &__text  {
                font-size: 16px;
                line-height: 24px;
                color: white;
            }
            &__title {
                font-weight: 600;
                letter-spacing: .07px;
                max-width: 174px;
            }
            &__text {
                font-weight: 300;
                letter-spacing: .05px;
                max-width: none;
                margin-top: 6px;
                padding-right: 4px;
            }
        }
        .cta {
            margin-top: 16px;
            .c-cta {
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                color: #384044;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 22px;
                    height: 22px;
                    margin-right: 8px;
                }
            }
        }
    }
    &__modal-form--myb-unique {
        &.c-callmeback__modal-form {
            padding: 32px 20px;
            // overflow: auto;
            // height: 655px;
            @media (min-width: @desktop) {
                padding: 32px 48px;
            }
            
            .c-callmeback__modal-form--myb-unique--logo {
                img {
                    display: block;
                    width: 205px;
                }
            }
            .c-callmeback__modal-form-title {
                font-weight: 600;
                text-align: left;
                margin: 16px auto 8px 0;
                max-width: none;
                @media (min-width: @desktop) {
                    margin: 16px auto;
                }
            }
            .c-callmeback__modal-form-subtitle {
                text-align: left;
                margin-bottom: 32px;
                font-weight: 600;
            }
            .c-fieldset label {
                margin-bottom: 0;
            }
            label.c-callmeback__modal--call-you {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                margin-bottom: 14px;
            }
            input[type="radio"] {
                border-radius: 99px;
                box-shadow: none;
                border: 1px solid #384044;
            }
            input[type="checkbox"], input[type="checkbox"]:checked, input[type="radio"], input[type="radio"]:checked, input[type="date"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], select {
                box-shadow: none;
                border: 1px solid #384044;
                padding-left: 12px;
            }
            .c-fieldset__row input[type="radio"]~label, 
            .c-fieldset__row-wrap input[type="radio"]~label ~ label {
                font-size: 16px;
                line-height: 24px;
                font-weight: 300;
                margin-top: 0px;
            }
    
             .c-fieldset__row {
                .iti--allow-dropdown {
                    &::after {
                        border: none;
                    }
                    .iti__flag-container {
                        border: 1px solid #384044;
                        .iti__selected-flag {
                            border: none;
                            padding: 0 6px 0 6px;
                        }
                    }
                    input[type="tel"].intlTelInput {
                        border: none;
                        padding-left: 0;
                        width: calc(100% - 64px);
                        background: none;
                        &::placeholder {
                            font-weight: 300;
                            color: #384044;
                        }
                    }
                }
                input[type=checkbox].call-me-back-input-contact-policy:checked {
                    border-radius: 0;
                }
            }
            .c-fieldset__row-wrap input[type=checkbox].call-me-back-input-contact-policy~.policy-label {
                text-align: left;
                margin-left: 8px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 300;
                a {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                }
            }
            #call-me-back-input-contact-name::placeholder, #call-me-back-input-contact-surname::placeholder {
                color: #384044;
                font-weight: 400;
            }
            hr {
                margin: 24px 0;
            }
            .c-fieldset.mod--cta-primary {
                margin: 0;
                .c-fieldset__row {
                    padding: 0;
                }
                .c-cta {
                    margin-right: 0;
                }
            }
            .error-message {
                font-size: 14px;
                line-height: 20px;
                font-weight: 300;
                color: @red-error-dark;
                margin-top: 4px;
            }
            input[type="text"].mod--invalidate,
            input[type="text"].mod--invalidate:focus,
            .c-fieldset__row .iti--allow-dropdown input.intlTelInput.mod--invalidate,
            .c-fieldset__row .iti--allow-dropdown input.intlTelInput.mod--invalidate:focus,
            form.rounded input[type="tel"].mod--invalidate,
            input[type="checkbox"].call-me-back-input-contact-policy.mod--invalidate,
            input[type="radio"].mod--invalidate {
                border-color: @red-error-dark;
                box-shadow: none;
                background: none;
            }
        }
    }
    &__modal--success--myb-unique {
        &.c-callmeback__modal--success {
            margin: 72px 24px 50px;
            padding: 0;
            width: 364px;
            .c-callmeback__modal--success-title {
                line-height: 34px;
                font-weight: 600;
            }
            .c-callmeback__modal--success-text {
                max-width: none;
                margin: 0 auto 24px;
            }
            .c-callmeback__modal--success-icon {
                margin: 0;
            }
        }
    }


}

.activeMegamenu {
    .c-callmeback {
        z-index: 5;
    }
}
// MODAL 
.call-me-back-form {
    .tingle-modal {
        &__close {
            top: 24px;
            right: 24px;
        }
        &__closeIcon {
            width: 24px;
            height: 24px;
            padding: 0 4px;
        }
    }
}
// EDITOR
.call-me-back-form {
    &.cq-Editable-dom {
        position: relative;
        display: block;
    
        .c-callmeback {
            position: relative;
            justify-content: end;
            margin: 0 auto;
            &__banner {
                &--claim {
                    margin-right: 60px;
                    &-close {
                        .svg-icon {
                            width: 13px;
                        }
                    }
                }
            }
        }
        .tingle-modal {
            visibility: visible;
            opacity: 1;
        }
    }
}

@media screen and (orientation:landscape) {
    .c-callmeback {
        bottom: ~"calc(41% - 70px)";
    }
}

@media (max-height: 650px) {
    .call-me-back-form {
        .tingle-modal {
            overflow-y: scroll;
            .tingle-modal-box {
                min-height: 615px;
            }
        }
    }
}

@media (min-width: @desktop) {
    .c-callmeback {
        z-index: 9;
        bottom: 2pc;
        &__modal-form {
            max-width: 536px;
            padding: 72px 78px 46px;
            &-title {
                max-width: 100%;
                font-size: 28px;
            }
            &-subtitle {
                font-size: 20px;
            }
            .c-fieldset {
                margin: 16px -15px;
                &__row {
                    .iti--allow-dropdown {
                        & ~ .contact-tel-prefix {
                            position: absolute;
                            left: 107px;
                        }
                    }
                    .iti__country-list {
                        height: 250px;
                        min-height: 250px;
                        &::-webkit-scrollbar-thumb {
                            border-top: none;
                        }
                    }
                }
            }
        }
    }
}