.c-checkin-form {
    .container-client {
        &__title {
            span {
                font-size: 18px;
                line-height: 26px;
                font-weight: 700;
                margin-right: 0.25em;
            }
        }
        &__services {
            display: flex;
            flex-flow: column;
            width: 100%;
            padding: 15px;
            margin: 17px 0px;
            border-radius: @soft-corners;
            box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.3);
            &-item {
                display: flex;
                align-items: center;
                .cmp-icon {
                    margin-right: 10px;
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .c-checkin-form {
        .container-client {
            &__title {
                margin: 15px 0px;
                span {
                    font-size: 20px;
                    line-height: 28px;
                    margin-right: 0.25em;
                }
            }
            &__services {
                flex-flow: row;
                align-items: center;
                width: 65.58%;
                padding: 17px 25px;
                margin: 23px 0px;
                &-item {
                    margin-right: 9.65%;
                    .cmp-icon {
                        height: 30px;
                        width: 30px;
                    }
                }
            }
        }
    }
}
