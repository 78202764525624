.c-form-conditions {
    .container-menu {
        display: flex;
        justify-content: space-around;
    }
    input[type="button"] {
        min-width: 100%;
        height: 40px;
        margin-top: 24px;
    }
    &__nav {
        .break-container;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-size: 14px;
        background: @grey-20;
        &-item {
            margin: 0px 6px;
        }
    }
}

.container-search {
    position: relative;
}

/* GENERIC CONTAINER -  DESKTOP  */
@media (min-width: @desktop) {
    .c-form-conditions {
        input[type="button"] {
            min-width: 171px;
            margin-top: 0px;
        }
        &__nav {
            .default-container;
            &-item {
                margin: 0px 13px;
            }
        }
    }
}
